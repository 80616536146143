// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react';
import customStyle from './candidaturas.css';
import {Image,Button,Form} from 'react-bootstrap';
import Icon from '../../images/happyPeople.jpg';
import axios from "axios";
import AlertC from "../alert/alert";
import SpinnerC from "../spinner/spinner";

const textCV = 'Carregue o seu Currículo';
const acceptedFiles = 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword';


class Candidaturas extends React.Component {

    //construtor method
  constructor() {
    super();
    
    //setting global variables to store form inputs values
    this.name = '';
    this.email = '';
    this.message = '';
    this.phone = '';
    this.state = {fileName: textCV, showAlert: false, showSpinner: false, titleAlert : '', messageAlert : '', variant : '', candidaturasRender : false};
  }

      //handling input changes
      handleNameChanges = (event) => {
        this.name  = event.target.value;
    }

    handleEmailChanges = (event) => {
        this.email  = event.target.value;
    }

    handlePhoneChanges = (event) => {
        this.phone  = event.target.value;
    }

    handleMessagesChanges = (event) => {
        this.message  = event.target.value;
    }

    handleFileChanges = (event) => {
        const file = event.target.files[0];
        const acceptedFormats = acceptedFiles.split(',');
        let acceptedFile = false;

        for(let i=0; i<acceptedFormats.length; i++){
            if(file.type ===acceptedFormats[i]){
                this.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
                this.setState({fileName : event.target.files[0].name});
                acceptedFile = true;
                break;
            }
        }

        //if the file was not accepted let´s inform the user
        if(acceptedFile === false){
            this.setState({showAlert : true, 
            variant: 'danger',
            titleAlert : 'Ficheiro especificado não é suportado', 
            messageAlert: 'Selecione um documento no formato PDF ou Word'});
        }
        
    }

    send(){
        //show spinner
        this.setState({showSpinner : true});
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('msg', this.message);
        formData.append('phone', this.phone);
        formData.append('subject', 'Nova Candidatura');
        formData.append('mode', 'Candidaturas');
        formData.append('file', this.file);
        var self= this; // self will now be referred react cmp, since .this inside axios is refering the axious itself
        axios.post('https://backendfvsolutions.herokuapp.com/send', 
            formData,
            {
            headers: {
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            }
            })
            .then(function (response) {
                if(response.status == 200){
                    self.setState({showAlert : true, 
                    variant: 'success',
                    titleAlert : 'Nova candidatura enviada com sucesso', 
                    messageAlert: 'Entraremos em contacto brevemente'});
                    // clear form inputs
                    self.clearInputs();
                    //hide spinner
                    self.setState({showSpinner : false});
                }
                
            })
            .catch(function (error) {
                // handle error
                self.setState({showAlert : true, 
                variant: 'danger',
                titleAlert : 'Ocorreu um erro...', 
                messageAlert: 'Tente novamente. Caso o erro persista, use uma via alternativa de contacto'});
                //hide spinner
                self.setState({showSpinner : false});
            })
    }

    clearInputs = () => {
        this.setState({fileName : textCV, candidaturasRender : !this.state.candidaturasRender})
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        else{
            //if valid let's send it to backend
            this.send();
        }
    };

    render() {
        return (
            <div key={this.state.candidaturasRender} className="candidaturas animate__animated animate__fadeIn">
                <div className="container">
                    {/* alert to how on form subtmit */}
                    {/* using key to rerender on showalert change */}
                    <div key={this.state.showAlert}>
                        <AlertC show={this.state.showAlert} title={this.state.titleAlert} message={this.state.messageAlert} variant={this.state.variant}/>
                        </div>
                    <Image className="imgCustomH" src={Icon} rounded/>
                    <div className="row">
                        <div className="col-sm-3 pt-5">
                            {/* <Image className="contactImg" src="https://miro.medium.com/max/768/1*Oi1EVhNAjYS852-HTRG-6w.png" rounded /> */}
                            <h4>Junta-te a nós!</h4>
                            <h6>Procuramos:</h6>
                            <div><i className="fas fa-caret-right px-2"></i>Dinâmismo</div>
                            <div><i className="fas fa-caret-right px-2"></i>Empenho</div>
                            <div><i className="fas fa-caret-right px-2"></i>Experiência</div>
                            <div><i className="fas fa-caret-right px-2"></i>Vontade de Aprender</div>
                        </div>
                        <Form className="col-sm-9 pt-5" onSubmit={this.handleSubmit}>
                            <h4 className="pb-3">Formulário de Candidatura</h4>
                            <Form.Group controlId="formNome">
                                <Form.Control required type="text" className="form-control" placeholder="Nome" onChange={this.handleNameChanges.bind(this)}/>
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Control required type="email" className="form-control" placeholder="Email" onChange={this.handleEmailChanges.bind(this)}/>
                            </Form.Group>
                            <Form.Group controlId="formContacto">
                                <Form.Control pattern="[0-9]{9}" required type="tel" className="form-control"  placeholder="Contacto" onChange={this.handlePhoneChanges.bind(this)}/>
                            </Form.Group>
                            <Form.Group controlId="formMensagem">
                                <Form.Control as="textarea" rows={3} placeholder="Mensagem" onChange={this.handleMessagesChanges.bind(this)}/>
                            </Form.Group>
                            <Form.Group controlId="formCv">
                                <Form.File accept={acceptedFiles} required label={this.state.fileName} data-browse="Carregar" lang="pt" onChange={this.handleFileChanges.bind(this)} custom/>
                            </Form.Group>
                            <Button className="butttonSend" variant="" type="submit">
                                Enviar 
                            </Button> 
                            {/* spinner to handle server side action */}
                            <div key={this.state.showSpinner}><SpinnerC show={this.state.showSpinner}/></div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Candidaturas;