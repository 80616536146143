// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react';
import customStyle from './produtos.css';
import {Image} from 'react-bootstrap';
import palletsPhoto from '../../images/1.jpg'; 
import ceramicPhoto from '../../images/4.jpg'; 
import indGeralPhoto from '../../images/indGeral.jpg'; 
import toolsPhoto from '../../images/tools.jpg'; 
import moPhoto from '../../images/construcao_obras.jpg';
import ccPhoto from '../../images/pintura-de-construcao-civil.jpg';







class Produtos extends React.Component {

    render() {
        return (
            <div className="produtos pb-5 animate__animated animate__fadeIn">

                {/* product iteration */}
                <div className="container animate__animated animate__fadeIn pt-5">
                    <div className="row">
                        {/* image */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <Image className="productImg" src={palletsPhoto} rounded />
                        </div>
                        {/* title and description */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <h3 className="text-center mt-3">Indústria Pellets</h3>
                            <div className="text-center">
                                A indústria de biomassa e pellets encontra-se em expansão. Trata-se de um produto natural, renovável com impacto neutro nas emissões de CO2 durante a queima, são cada vez mais utilizados tanto a nível doméstico como industrial, em salamandras, recuperadores, fornos, caldeiras e churrasqueiras.                            </div>
                            <div className="text-center">
                                A FV Solutions tem a capacidade de fornecer para estas industrias consumíveis e peças de substituição para todos os equipamentos de produção e tratamento de pellets.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        {/* image */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <Image className="productImg" src={ceramicPhoto} rounded />
                        </div>
                        {/* title and description */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <h3 className="text-center mt-3">Indústria Cerâmica</h3>
                            <div className="text-center">
                                A indústria cerâmica atravessa uma fase de modernização, assumindo-se como um dos maiores sectores produtivos e exportadores do nosso pais. Nos seus vários ramos (pavimento, revestimento, louça decorativa, barro vermelho e outros) emprega um grande número de pessoas e produz centenas de toneladas de produto acabado diariamente.                            </div>
                            <div className="text-center">
                                A FV Solutions tem a capacidade de fornecer para estas industrias consumíveis e peças de substituição para todos os equipamentos de produção de todo o tipo de produtos cerâmicos.
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container mt-5">
                    <div className="row">
                        {/* image */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <Image className="productImg" src={indGeralPhoto} rounded />
                        </div>
                        {/* title and description */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <h3 className="text-center mt-3">Indústria Geral</h3>
                            <div className="text-center">
                                O setor indústrial é responsável pela transformação de matéria-prima e pela produção de todos os produtos utilizados no nosso dia-a-dia.
                            </div>
                            <div className="text-center">
                                Desde as industrias pesadas (cimenteiras, siderurgias, petroquímicas, papeleiras, centrais electricas) até ás industrias de bens de consumo (textil, calçado, alimentar), passando pelas industrias de bens intermediários (automóvel, ferramentaria, plásticos). Para todas estas industrias a FV Solutions tem soluções, produtos e serviços para ajudar a atingir os seus objectivos.                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        {/* image */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <Image className="productImg" src={toolsPhoto} rounded />
                        </div>
                        {/* title and description */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <h3 className="text-center mt-3">Máquinas e ferramenta</h3>
                            <div className="text-center">
                                As máquinas e as ferramentas são utilizadas em toda a indústria, na manutenção de todo o tipo de máquinas. A FV Solutions dispõe de soluções para o fornecimento de máquinas e ferramentas para o sector indústrial, automóvel e doméstico.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        {/* image */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <Image className="productImg" src={moPhoto} rounded />
                        </div>
                        {/* title and description */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <h3 className="text-center mt-3">Mão de Obra Especializada</h3>
                            <div className="text-center">
                                As empresas ocasionalmente necessitam de mão de obra, para o exercício de serviços com duração mais ou menos curta, como substituição de funcionários ausentes, picos de produção, trabalhos técnicos, entre outros.
                            </div>
                            <div className="text-center">
                                A FV Solutions pode fornecer a mão de obra de que necessitam, em regime de prestação de serviços, tais como:
                            </div>
                            <div className="text-center"><i className="fas fa-caret-right px-2"/>Serralheiros</div>
                            <div className="text-center"><i className="fas fa-caret-right px-2"/>Electricistas</div>
                            <div className="text-center"><i className="fas fa-caret-right px-2"/>Ajudantes</div>
                            <div className="text-center"><i className="fas fa-caret-right px-2"/>Operários Fabris</div>
                            <div className="text-center"><i className="fas fa-caret-right px-2"/>Indiferenciados</div>
                            <div className="text-center"><i className="fas fa-caret-right px-2"/>Pessoal Limpeza</div>
                            <div className="text-center"><i className="fas fa-caret-right px-2"/>Construção Civil</div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        {/* image */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <Image className="productImg" src={ccPhoto} rounded />
                        </div>
                        {/* title and description */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 justify-content-center align-self-center">
                            <h3 className="text-center mt-3">Construção Civíl</h3>
                            <div className="text-center">
                                Estamos aptos a efectuar obras de pequena e média dimensão, com ou sem orçamento, remodelações, isolamentos, entre outros.                            
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        );
    }
}

export default Produtos;