// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react';
import customStyle from './termosUtilizacao.css';


class Termos extends React.Component {
    render() {
        return (
           
            <div className="termos container">
                <div></div><br/>
                <div><strong className="fontLarge">TERMOS E CONDIÇÕES</strong></div><br/>
                <div><strong>VISÃO GERAL</strong></div><br/>
                <div>Esse site é operado pela empresa Fadas Verdadeiras, Lda. Em todo o site, os termos “nós”, “nos” e “nosso” referem-se á empresa Fadas Verdadeiras, Lda. A empresa Fadas Verdadeiras, Lda disponibiliza este site, incluindo todas as informações, ferramentas e serviços disponíveis para si, o utilizador, com a condição da sua aceitação de todos os termos, condições, políticas e avisos declarados aqui.
                Ao visitar o nosso site, está a utilizar os nossos “Serviços”. Consequentemente, você concorda com os seguintes termos e condições (“Termos de serviço”, “Termos”), incluindo os termos, condições e políticas adicionais mencionados neste documento. Estes termos de serviço aplicam-se a todos os utilizadores do site, incluindo, sem limitação, os utilizadores que são visitantes, fornecedores, clientes e/ou contribuidores de conteúdo.
                Por favor, leia esses Termos de Serviço cuidadosamente antes de entrar ou utilizar o nosso site. Ao entrar ou usar qualquer parte do site, você concorda com os Termos de Serviço. Se você não concorda com todos os termos e condições desse acordo, então não pode entrar no site ou usar quaisquer serviços.
                Quaisquer novos recursos ou ferramentas que forem adicionados ao site atual também devem estar sujeitos aos Termos de Serviço. Você pode revisar a versão mais atual dos Termos de Serviço quando quiser nesta página. Reservamos o direito de atualizar, alterar ou trocar qualquer parte desses Termos de Serviço ao publicar atualizações e/ou alterações no nosso site. É sua responsabilidade verificar as alterações feitas nesta página periodicamente. Seu uso contínuo ou acesso ao site após a publicação de quaisquer alterações constitui aceitação de tais alterações.
                </div><br/>
                <div><strong>CONDIÇÕES GERAIS</strong></div><br/>
                <div>Reservamos o direito de recusar o serviço, a qualquer pessoa, por qualquer motivo a qualquer momento.
                Concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do Serviço, uso do Serviço, acesso ao Serviço, ou qualquer contacto no site, sem a nossa permissão expressa por escrito.
                Os títulos usados nesse acordo são incluídos apenas por conveniência e não limitam ou afetam os Termos.
                </div><br/>
                <div><strong>PRECISÃO, INTEGRIDADE E ATUALIZAÇÃO DAS INFORMAÇÕES</strong></div><br/>
                <div>Não somos responsáveis por informações disponibilizadas neste site que não sejam precisas, completas ou atuais. O material deste site é fornecido apenas para fins informativos, não deve ser usado como a única base para tomar decisões sem consultar fontes de informações primárias, mais precisas, mais completas ou mais atuais. Qualquer utilização do material deste site é por sua conta e risco.
                Este site pode conter certas informações históricas. As informações históricas podem não ser atuais e são fornecidas apenas para sua referência. Reservamos o direito de modificar o conteúdo deste site a qualquer momento, mas não temos obrigação de atualizar nenhuma informação no nosso site. Você concorda que é de sua responsabilidade monitorar alterações no nosso site.
                </div><br/>
                <div><strong>FERRAMENTAS OPCIONAIS</strong></div><br/>
                <div>
                FERRAMENTAS OPCIONAIS
                Podemos dar acesso a ferramentas de terceiros que não monitorizamos e nem sobre as quais temos qualquer controlo.
                Você reconhece e concorda que nós fornecemos acesso a tais ferramentas ”como elas são” e “conforme a disponibilidade” sem quaisquer garantias, representações, condições de qualquer tipo e sem qualquer endosso. Não nos responsabilizamos de forma alguma pelo uso de ferramentas opcionais de terceiros.
                Qualquer uso de ferramentas opcionais oferecidas através do site é inteiramente por sua conta e risco. Você deve aprovar os termos das ferramentas que são fornecidas por fornecedor(es) terceiro(s).
                </div><br/>
                <div><strong>LINKS DE TERCEIROS</strong></div><br/>
                <div>
                Certos conteúdos e serviços disponíveis pelo nosso Serviço podem incluir materiais de terceiros.
                Os links de terceiros nesse site podem direcionar para sites que não são afiliados a nós. Não nos responsabilizamos por examinar, avaliar o conteúdo ou a sua precisão. Não garantimos e nem temos obrigação ou responsabilidade por sites de terceiros, ou por quaisquer outros materiais, produtos ou serviços de terceiros.
                Não somos responsáveis por quaisquer danos ou prejuízos relacionados com a compra ou uso de mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão com quaisquer sites de terceiros. Por favor, revise com cuidado as políticas e práticas de terceiros e certifique-se que as entende antes de efetuar qualquer transação. As queixas, reclamações, preocupações ou questões relativas a produtos de terceiros devem ser direcionadas ao terceiro.
                </div><br/>
                <div><strong>COMENTÁRIOS, FEEDBACK, ETC. DO UTILIZADOR</strong></div><br/>
                <div>
                Se, a nosso pedido , você enviar ideias criativas, sugestões, propostas, planos, ou outros materiais, seja online, por e-mail, pelo correio, ou de outra forma (em conjunto chamados de ‘comentários’), você concorda que podemos a qualquer momento, sem restrição, editar, copiar, publicar, distribuir, traduzir e de outra forma usar quaisquer comentários que você encaminhar para nós. Não nos responsabilizamos por: (1) manter quaisquer comentários em sigilo; (2) indemnizar por quaisquer comentários; ou (3) responder a quaisquer comentários.
                Podemos, mas não temos a obrigação, de monitorar, editar ou remover conteúdo que determinamos a nosso próprio critério ser contra a lei, ofensivo, ameaçador, calunioso, difamatório, pornográfico, obsceno, censurável ou que viole a propriedade intelectual de terceiros ou estes Termos de serviço.
                Você concorda que os seus comentários não violarão qualquer direito de terceiros, incluindo direitos autorais, marcas registadas, privacidade, personalidade ou outro direito pessoal ou de propriedade. Você concorda que os seus comentários não vão conter material difamatório, ilegal, abusivo ou obsceno. Também não conterão nenhum vírus de computador ou outro malware que possa afetar a operação do Serviço ou qualquer site relacionado. Não pode usar um endereço de e-mail falso, fingir ser alguém diferente de si mesmo, ou de outra forma enganar-nos ou a terceiros quanto à origem de quaisquer comentários. Você é o único responsável por quaisquer comentários que faz e pela veracidade dos mesmos. Não assumimos qualquer responsabilidade ou obrigação por quaisquer comentários publicados por você ou por qualquer terceiro.
                </div><br/>
                <div><strong>INFORMAÇÕES PESSOAIS</strong></div><br/>
                <div>
                O envio de suas informações pessoais através do site é regido pela nossa Política de privacidade. Ver a nossa Política de privacidade.
                </div><br/>
                <div><strong>ERROS, IMPRECISÕES E OMISSÕES</strong></div><br/>
                <div>
                Ocasionalmente, pode haver informações no nosso site ou no Serviço que contém erros tipográficos, imprecisões ou omissões. Reservamos o direito de corrigir quaisquer erros, imprecisões ou omissões, e de alterar ou actualizar informações .
                Não assumimos nenhuma obrigação de actualizar, alterar ou esclarecer informações no Serviço ou em qualquer site relacionado, incluindo, sem limitação, a informações sobre preços, excepto conforme exigido por lei. Nenhuma actualização específica ou data de actualização no Serviço ou em qualquer site relacionado, deve ser utilizada para indicar que todas as informações tenham sido modificadas ou actualizadas.
                </div><br/>
                <div><strong>USOS PROIBIDOS</strong></div><br/>
                <div>Além de outras proibições, conforme estabelecido nos Termos de Serviço, você está proibido de usar o site ou o conteúdo para: (a) fins ilícitos; (b) solicitar a outras pessoas para realizar ou participar em quaisquer atos ilícitos; (c) violar quaisquer regulamentos internacionais, regras, leis ou regulamentos locais; (d) infringir ou violar os nossos direitos de propriedade intelectual ou os direitos de propriedade intelectual de terceiros; (e) para assediar, abusar, insultar, danificar, difamar, caluniar, depreciar, intimidar ou discriminar com base em género, orientação sexual, religião, etnia, raça, idade, nacionalidade ou deficiência; (f) apresentar informações falsas ou enganosas; (g) fazer o envio ou transmitir vírus ou qualquer outro tipo de código malicioso que será ou poderá ser usado para afectar a funcionalidade ou operação do Serviço ou de qualquer site relacionado, outros sites, ou da Internet; (h) coletar ou rastrear as informações pessoais de outras pessoas; (i) para enviar spam, phishing, pharm, pretext, spider, crawl, ou scrape; (j) para fins obscenos ou imorais; ou (k) para interferir ou contornar os recursos de segurança do Serviço ou de qualquer site
                relacionado, outros sites, ou da Internet. Reservamos o direito de rescindir o seu uso do Serviço, ou de qualquer site relacionado, por violar qualquer um dos usos proibidos.
                </div><br/>
                <div><strong>ISENÇÃO DE RESPONSABILIDADE DE GARANTIAS; LIMITAÇÃO DE RESPONSABILIDADE</strong></div><br/>
                <div>Não garantimos, representamos ou justificamos que o seu uso do nosso serviço será pontual, seguro, sem erros ou interrupções.
                Não garantimos que os resultados que possam ser obtidos pelo uso do serviço serão precisos ou confiáveis.
                Você concorda que de tempos em tempos, podemos remover o serviço por períodos indefinidos de tempo ou cancelar a qualquer momento, sem notificação prévia.
                Você concorda que o seu uso ou incapacidade de usar o serviço é por sua conta e risco. O serviço (exceto conforme declarado por nós) é fornecido sem garantia e conforme a disponibilidade para o seu uso, sem qualquer representação, garantias ou condições de qualquer tipo, expressas ou implícitas.
                Em nenhuma circunstância a empresa Fadas Verdadeiras, Lda, os seus directores, funcionários, afiliados, agentes, contratantes, estagiários, fornecedores e prestadores de serviços serão responsáveis por qualquer prejuízo, perda, reclamação ou danos directos, indirectos, acidentais, punitivos, especiais ou consequentes de qualquer tipo, incluindo, sem limitação, lucros cessantes, perda de receita, poupanças perdidas, perda de dados, custos de reposição, ou quaisquer danos semelhantes, seja com base em contrato, acto ilícito (incluindo negligência), responsabilidade objectiva ou de outra forma, decorrentes do uso de qualquer um dos serviços ou quaisquer produtos adquiridos usando o serviço, ou para qualquer outra reclamação relacionada de alguma forma com o uso do serviço ou qualquer produto, incluindo, mas não limitado a, quaisquer erros ou omissões em qualquer conteúdo, ou qualquer perda ou dano de qualquer tipo como resultado do uso do serviço ou qualquer conteúdo (ou produto) publicado, transmitido ou de outra forma disponível através do serviço, mesmo se alertado de tal possibilidade. Como alguns países não permitem a exclusão ou a limitação de responsabilidade por danos consequentes ou acidentais, a nossa responsabilidade será limitada à extensão máxima permitida por lei.
                </div><br/>
                <div><strong>INDEMNIZAÇÃO</strong></div><br/>
                <div>Você concorda em indemnizar, defender e isentar a empresa Fadas Verdadeiras, Lda e os nossos subsidiários, afiliados, parceiros, funcionários, directores, agentes, contratados, licenciantes, prestadores de serviços, subcontratados, fornecedores, estagiários e funcionários, de qualquer reclamação ou demanda, incluindo honorários de advogados, por quaisquer terceiros devido à violação destes Termos de Serviço ou aos documentos que incorporam por referência, ou à violação de qualquer lei ou os direitos de um terceiro.
                </div><br/>
                <div><strong>INDEPENDÊNCIA</strong></div><br/>
                <div>No caso de qualquer disposição destes Termos de serviço ser considerada ilegal, nula ou ineficaz, tal disposição deve, contudo, ser aplicável até ao limite máximo permitido pela lei aplicável, e a porção inexequível será considerada separada desses Termos de serviço. Tal determinação não prejudica a validade e aplicabilidade de quaisquer outras disposições restantes.
                </div><br/>
                <div><strong>RESCISÃO</strong></div><br/>
                <div>As obrigações e responsabilidades das partes incorridas antes da data de rescisão devem continuar após a rescisão deste acordo para todos os efeitos.
                Estes Termos de Serviço estão em vigor, a menos que seja rescindido por você ou por nós. Você pode rescindir estes Termos de serviço a qualquer momento, notificando-nos que já não deseja utilizar os nossos serviços, ou quando deixar de usar o nosso site.
                Caso segundo o nosso critério exclusivo você não cumprir com qualquer termo ou disposição destes Termos de Serviço, também, podemos rescindir este contrato a qualquer momento sem aviso prévio e ficará responsável por todas as quantias devidas até a data da rescisão; também, podemos negar o acesso a nossos Serviços (ou qualquer parte deles).
                </div><br/>
                <div><strong>ACORDO INTEGRAL</strong></div><br/>
                <div>Caso não exerçamos ou executemos qualquer direito ou disposição destes Termos de Serviço, isso não constituirá uma renúncia de tal direito ou disposição.
                Estes Termos de Serviço e quaisquer políticas, ou normas operacionais, postadas por nós neste site ou no que diz respeito ao serviço, constituem a totalidade do acordo entre nós.
                Estes termos regem o uso do Serviço, substituindo quaisquer acordos anteriores ou contemporâneos, comunicações e propostas, sejam verbais ou escritos, entre o utilizador e a empresa Fadas Verdadeiras, Lda, (incluindo, mas não limitado a quaisquer versões anteriores dos Termos de Serviço). Quaisquer ambiguidades na interpretação destes Termos de serviço não devem ser interpretadas contra a parte que os redigiu.
                </div><br/>
                <div><strong>LEGISLAÇÃO APLICÁVEL</strong></div><br/>
                <div>Esses Termos de serviço e quaisquer acordos separados em que lhe fornecemos os Serviços devem ser regidos e interpretados de acordo com as leis de Portugal.
                </div><br/>
                <div><strong>ALTERAÇÕES DOS TERMOS DE SERVIÇO</strong></div><br/>
                <div>Pode rever a versão mais actualizada dos Termos de serviço a qualquer momento nesta página.
                Reservamos o direito, a nosso critério, de actualizar, modificar ou substituir qualquer parte destes Termos de serviço ao publicar actualizações e alterações no nosso site. É sua responsabilidade verificar as mesmas no nosso site periodicamente. O seu uso contínuo, acesso ao nosso site ou ao Serviço após a publicação de quaisquer alterações a estes Termos de serviço, constitui aceitação dessas alterações.
                </div><br/>
                <div><strong>CONTACTO</strong></div><br/>
                <div>As perguntas sobre os Termos de serviço devem ser enviadas através do email <a href="mailto:comercial@fvsolutions.pt">comercial@fvsolutions.pt</a> ou por escrito para:</div><br/>
                <div><strong>Fadas Verdadeiras, Lda</strong></div>
                <div><strong>R. Vale S. Miguel, Lote 5, R/C esq. Trás</strong></div>
                <div><strong>3020-113 Coimbra</strong></div>
                <div><strong>Portugal</strong></div><br/>
                
                </div>
        );
    }
}

export default Termos;