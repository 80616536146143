// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, { useEffect, useState, render} from "react";
import {Spinner} from 'react-bootstrap';

function SpinnerC(props) {

    //init variables, using props
    const [showSpinner ,setShowSpinner] = useState( props.show );

    if (showSpinner === true) {
        return (
            <div>
                <Spinner animation="border" role="status"/>
            </div>

        );
    }
    return(<div></div>);
}

export default SpinnerC;