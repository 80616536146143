import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from './components/navigationBar/navigationBar';
import Footer from './components/footer/footer';
import Rotes from './services/rotes.service';


class App extends Component {
  
  //construtor method
  constructor() {
    super();
    // this.state.selectedTab='home';
  }

  //handle tab click from Navigation cmp
  handleSelectedtab = (selectedTab) => {
    // this.state.selectedTab = selectedTab;
    // console.log(selectedTab);
  }

  //function to render the correct layout
  render() {
    return (
      <div >
        {/*routes client side*/}
        {/* this include page body and navigation bar */}
        <Rotes />

        {/* Footer Cmp */}
        <Footer/>

      </div>
    );
  }
}

export default App;
