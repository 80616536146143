// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react';
import customStyle from './politicaPrivacidade.css';


class Politica extends React.Component {
    render() {
        return (
            <div className="privacidade container">
                <div></div><br/>
                <div><strong className="fontLarge">POLÍTICA DE PRIVACIDADE</strong></div><br/>
                <div><strong>POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS</strong></div><br/>
                <div>Ultima actualização: 04 de Março de 2021.</div><br/>
                <div>A privacidade e a proteção de dados representam uma preocupação com prioridade elevada para a empresa Fadas Verdadeiras, Lda. A utilização das páginas da Internet da empresa Fadas Verdadeiras, Lda é possível sem qualquer indicação de dados pessoais, no entanto, se um titular de dados desejar vir a utilizar os serviços da empresa Fadas Verdadeiras, Lda, o tratamento de dados pessoais pode ser necessário. Se o tratamento de dados pessoais for necessário e não houver outra base legal para tal tratamento, iremos solicitar o consentimento do titular dos dados.
                O tratamento de dados pessoais – como o nome, morada, endereço de e-mail, ou número de telefone – de um titular dos dados deve estar sempre em conformidade com o Regulamento Geral de Proteção de Dados (RGPD) e de acordo com os regulamentos de proteção de dados específicos do país e aplicáveis à empresa Fadas Verdadeiras, Lda. Através desta declaração de proteção de dados, gostaríamos de informar o público em geral sobre a natureza, alcance e finalidade dos dados pessoais que recolhemos, utilizamos e processamos. Além disso, os titulares dos dados são informados, por meio desta declaração de proteção de dados, dos direitos que os assistem.
                </div><br/>
                <div><strong>NOME E ENDEREÇO DO RESPONSÁVEL PELO TRATAMENTO</strong></div><br/>
                <div>
                O Responsável pelo tratamento, para efeitos do Regulamento Geral de Proteção de Dados (RGPD), outras leis de proteção de dados aplicáveis nos Estados-Membros da União Europeia e outras disposições relacionadas com a proteção de dados, é:
                </div>
                <div>Fadas Verdadeiras, Lda </div>
                <div>R. Vale S. Miguel, Lote 5, R/C Esq. Trás</div>
                <div>3020-113, Coimbra</div>
                <div>Portugal</div>
                <div>Telefone : +351 915404950</div>
                <div>Email: comercial@fvsolutions.pt</div>
                <div>Website: http://www.fvsolutions.pt</div>
                <br/><div><strong>COOKIES</strong></div><br/>
                <div>As páginas de internet da empresa Fadas Verdadeiras, Lda usam cookies. Cookies são pequenos ficheiros de texto que são armazenados num equipamento (computador, smartphone ou tablet), através de um navegador de internet (browser).
                Através da utilização de cookies, a empresa Fadas Verdadeiras, Lda pode disponibilizar aos utilizadores do seu website uma melhor experiência de utilização e torná-la mais simples, o que não seria possível sem a utilização dos mesmos. A informação recolhida pelos cookies traduz-se por estimativas numéricas, padrões de utilização e a adaptação do site aos interesses dos nossos utilizadores. Esta informação tem como finalidade melhorar o desempenho técnico do nosso website e a sua experiência de utilização.
                    O titular dos dados pode, em qualquer altura, evitar a utilização de cookies pelo nosso website através da configuração do navegador de internet utilizado, e dessa forma evitar com carácter permanente a utilização de cookies. Adicionalmente, os cookies já criados podem ser apagados em qualquer momento através do navegador de internet ou outros programas de software. Se o utilizador desativar a utilização de cookies no navegador de internet utilizado, nem todas as funcionalidades do nosso website podem estar inteiramente disponíveis
                </div><br/>
                <div><strong>RECOLHA DE DADOS E INFORMAÇÃO GERAL</strong></div><br/>
                <div>Considera-se informação pessoal qualquer informação que possa ser usada para identificar um indivíduo, incluindo (mas não limitado a) nome, endereço eletrónico, endereço postal, título, data de nascimento, sexo, profissão, interesses pessoais, sociais e culturais. As páginas de internet da empresa Fadas Verdadeiras, Lda recolhem estas informações quando um titular dos dados ou sistema automatizado navega nos sites. Estes dados e informação geral são armazenados nos ficheiros de log do servidor. Podem ser recolhidos os tipos de navegador e as versões utilizadas, o sistema operativo usado pelo sistema de acesso, o site do qual um sistema de acesso chega aos nossos sites (os chamados referentes), os sub-websites, a data e hora de acesso ao site da Internet, um endereço de protocolo de Internet (endereço IP), o fornecedor de
                serviços de Internet do sistema de acesso e outros dados similares e informações que podem ser usadas em caso de ataques aos nossos sistemas de tecnologia de informação.
                Esta informação é necessária para disponibilizar o conteúdo dos nossos sites corretamente, otimizar os conteúdos dos mesmos, bem como a sua divulgação, garantir a viabilidade a longo prazo dos nossos sistemas de tecnologia da informação e tecnologia dos sites, e fornecer às autoridades responsáveis pela aplicação da lei as informações necessárias para o processo penal em caso de ataque cibernético.
                </div><br/>
                <div><strong>SEGURANÇA E INTEGRIDADE DE DADOS PESSOAIS</strong></div><br/>
                <div>A empresa Fadas Verdadeiras, Lda toma todas as medidas necessárias para proteger os dados pessoais e os conteúdos dos utilizadores contra perda e uso indevido, bem assim como acesso não autorizado, divulgação, alteração e destruição.</div><br/>
                <div><strong>DIREITOS DOS TITULARES DOS DADOS</strong></div><br/>
                <div>Se o titular dos dados desejar exercer qualquer dos direitos que serão enumerados seguidamente, poderá em qualquer momento entrar em contacto com a empresa Fadas Verdadeiras, Lda através da informação fornecida no ponto 1.</div>
                <div><strong>Direito de acesso</strong></div>
                <div>O titular dos dados pessoais tem o direito a obter da empresa Fadas Verdadeiras, Lda a confirmação de que os dados que lhe digam respeito são ou não objeto de tratamento e, se for o caso, de aceder aos seus dados pessoais e aceder às informações previstas na lei.
                Caso pretenda mais do que uma cópia dos seus dados pessoais em fase de tratamento, a empresa Fadas Verdadeiras, Lda poderá sujeitar esse serviço ao pagamento de uma taxa pelos custos administrativos.</div>
                <div><strong>Direito à retificação</strong></div>
                <div>O titular dos dados tem o direito de obter da empresa Fadas Verdadeiras, Lda, sem demora indevida, a rectificação de dados pessoais imprecisos ou incompletos sobre si.</div>
                <div><strong>Direito de eliminação</strong></div>
                <div>Direito de eliminação
                O titular dos dados pessoais tem o direito a pedir á empresa Fadas Verdadeiras, Lda para apagar os seus dados, sem demora injustificada, e a empresa Fadas Verdadeiras, Lda tem a obrigação de apagar os dados.</div>
                <div><strong>Direito à limitação do tratamento</strong></div>
                <div>O titular dos dados tem o direito de obter da empresa Fadas Verdadeiras, Lda a limitação do tratamento, se se aplicar, designadamente, uma das seguintes situações:</div>
                <div><i className="fas fa-caret-right px-2"></i>Contestar a exatidão dos dados pessoais, durante um período que permita á empresa Fadas Verdadeiras, Lda verificar a sua exatidão;</div>
                <div><i className="fas fa-caret-right px-2"></i>O tratamento de dados for lícito e o titular dos dados se opuser a que se apaguem os seus dados pessoais e solicitar, em contrapartida, a limitação da sua utilização;</div>
                <div><i className="fas fa-caret-right px-2"></i>A empresa Fadas Verdadeiras, Lda já não precisar dos dados pessoais para fins de tratamento, mas esses dados são requeridos pelo titular para efeitos de declaração, exercício ou defesa de um direito num processo judicial;</div>
                <div><i className="fas fa-caret-right px-2"></i>Se tiver oposto ao tratamento, até se verificar que os motivos legítimos do responsável pelo tratamento prevalecem sobre os do titular dos dados.</div>
                <div><strong>Direito à portabilidade dos dados</strong></div>
                <div>O titular dos dados tem o direito de receber os dados pessoais que lhe digam respeito e que tenha fornecido á empresa Fadas Verdadeiras, Lda, quando esta última utiliza meios automáticos para o processamento, num formato estruturado, de uso corrente e de leitura automática, e o direito de transmitir esses dados a outro responsável pelo tratamento.
                Ao exercer o seu direito de portabilidade dos dados, o titular dos dados tem o direito a que os dados pessoais sejam transmitidos diretamente entre os responsáveis pelo tratamento, sempre que tal seja tecnicamente possível.</div>
                <div><strong>Direito de oposição</strong></div>
                <div>O titular dos dados tem o direito de se opor ao tratamento dos seus dados pessoais quando o processamento for realizado no âmbito do interesse legítimo da empresa Fadas Verdadeiras, Lda ou de terceiros, para efeitos de marketing directo ou definição de perfis.
                A empresa Fadas Verdadeiras, Lda cessa o processamento dos dados pessoais em caso de oposição, a menos que existam motivos legítimos convincentes para o processamento que anulam os interesses, direitos e liberdades do titular dos dados, ou para fundamentar o exercício ou defesa de reivindicações legais.</div>
                <div><strong>Direito de retirar o consentimento</strong></div>
                <div>Se o consentimento for legalmente necessário para o tratamento de dados pessoais, o titular dos dados tem o direito de retirar o consentimento em qualquer altura, embora esse direito não comprometa a licitude do tratamento efetuado com base no consentimento previamente dado nem o tratamento posterior dos mesmos dados, baseado noutra base legal, como é o caso do cumprimento do contrato ou da obrigação legal a que a empresa Fadas Verdadeiras, Lda esteja sujeita.</div>
                <br/><div><strong>BASE LEGAL PARA O TRATAMENTO</strong></div><br/>
                <div>O tratamento dos dados pessoais terá de ser suportado por uma base legal para o efeito que estará enquadrada numa das seguintes situações:</div>
                <div><i className="fas fa-caret-right px-2"></i>O titular dos dados tiver dado o seu consentimento para o tratamento dos seus dados pessoais para uma ou mais finalidades específicas;</div>
                <div><i className="fas fa-caret-right px-2"></i>O tratamento for necessário para a execução de um contrato no qual o titular dos dados é parte, ou para diligências pré-contratuais a pedido do titular dos dados;</div>
                <div><i className="fas fa-caret-right px-2"></i>O tratamento for necessário para o cumprimento de uma obrigação jurídica a que o responsável pelo tratamento esteja sujeito;</div>
                <div><i className="fas fa-caret-right px-2"></i>O tratamento for necessário para a defesa de interesses vitais do titular dos dados ou de outra pessoa singular;</div>
                <div><i className="fas fa-caret-right px-2"></i>O tratamento for necessário ao exercício de funções de interesse público ou ao exercício da autoridade pública de que está investido o responsável pelo tratamento;</div>
                <div><i className="fas fa-caret-right px-2"></i>O tratamento for necessário para efeito dos interesses legítimos prosseguidos pelo responsável pelo tratamento ou por terceiros, exceto se prevalecerem os interesses ou direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais, em especial se o titular for uma criança (não se aplica ao tratamento de dados efetuado por autoridades públicas na prossecução das suas atribuições por via eletrónica).</div>
                <br/><div><strong>FORNECIMENTO DE DADOS PESSOAIS COMO REQUISITO LEGAL OU CONTRATUA</strong></div><br/>
                <div>O fornecimento de dados pessoais em determinadas situações é exigido por lei (por exemplo, regulamentos fiscais) ou também pode resultar de disposições contratuais (por exemplo, informações sobre o parceiro contratual). Poderá ser necessário concluir um contrato em que o titular dos dados fornece dados pessoais, que devem ser posteriormente processados nesse âmbito. O titular dos dados é, por exemplo, obrigado a fornecer dados pessoais quando a empresa Fadas Verdadeiras, Lda assinar um contrato com ele.
                </div><br/>
                <div><strong>ACTUALIZAÇÃO DE POLITICA DE PRIVACIDADE</strong></div><br/>
                <div>A empresa Fadas Verdadeiras, Lda atualizará a presente Política de Privacidade quando necessário para refletir o feedback dos consumidores e alterações aos nossos produtos e serviços. Quando a empresa Fadas Verdadeiras, Lda efetuar alterações à presente Política de Privacidade, irá alterar a data relativa à “Última Atualização” no início desta Política. Caso as alterações sejam significativas, a empresa Fadas Verdadeiras, Lda disponibilizará um aviso mais destacado (incluindo, em alguns serviços, notificações por email das alterações à Política de
                Privacidade). A empresa Fadas Verdadeiras, Lda irá também manter em arquivo as versões prévias desta Política de Privacidade para que as possa examinar.
                </div><br/><br/>
            </div>
        );
    }
}

export default Politica;