// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react';
import customStyle from './contactos.css';
import {Image,Button,Form} from 'react-bootstrap';
import Icon from '../../images/FV_logo.png'
import axios from "axios";
import AlertC from "../alert/alert";
import SpinnerC from "../spinner/spinner";


const facebookLink = process.env.REACT_APP_FACEBOOK;
const email = process.env.REACT_APP_CONTACTO_EMAIL;
const phone = process.env.REACT_APP_PHONE;


class Contactos extends React.Component {

//construtor method
    constructor() {
        super();
        
        //setting global variables to store form inputs values
        this.name = '';
        this.email = '';
        this.message = '';
        this.phone = '';
        this.state = {showAlert: false, showSpinner: false, titleAlert : '', messageAlert : '', variant : '', contactosRender : false};
    }

    //handling input changes
    handleNameChanges = (event) => {
        this.name  = event.target.value;
    }

    handleEmailChanges = (event) => {
        this.email  = event.target.value;
    }

    handlePhoneChanges = (event) => {
        this.phone  = event.target.value;
    }

    handleMessagesChanges = (event) => {
        this.message  = event.target.value;
    }
   
    send = async event =>{
        //show spinner
        this.setState({showSpinner : true});
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('msg', this.message);
        formData.append('phone', this.phone);
        formData.append('subject', 'Novo Pedido de Contacto');
        formData.append('mode', 'Contactos');
        var self= this; // self will now be referred react cmp, since .this inside axios is refering the axious itself
        await axios.post('https://backendfvsolutions.herokuapp.com/send/', 
            formData,
            {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
            })
            .then(function (response) {
                if(response.status == 200){
                    self.setState({showAlert : true, 
                    variant: 'success',
                    titleAlert : 'Novo pedido de contacto registado', 
                    messageAlert: 'Entraremos em contacto brevemente'});
                    // clear form inputs
                    self.clearInputs();
                    //hide spinner
                    self.setState({showSpinner : false});
                }
                
            })
            .catch(function (error) {
                // handle error
                self.setState({showAlert : true, 
                variant: 'danger',
                titleAlert : 'Ocorreu um erro...', 
                messageAlert: 'Tente novamente. Caso o erro persista, use uma via alternativa de contacto'});
                //hide spinner
                self.setState({showSpinner : false});
            })
    }

    clearInputs = () => {
        this.setState({contactosRender : !this.state.contactosRender})
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        else{
            //if valid let's send it to backend
            return this.send();
        }
    };

    render() {
        return (
            <div key={this.state.contactosRender} className="contactos animate__animated animate__fadeIn">
                <div className="container">
                    {/* alert to how on form subtmit */}
                    {/* using key to rerender on showalert change */}
                    <div key={this.state.showAlert}>
                        <AlertC show={this.state.showAlert} title={this.state.titleAlert} message={this.state.messageAlert} variant={this.state.variant}/>
                        </div>
                    <div className="row">
                        <div className="col-xs-10 col-sm-6 col-lg-4 pt-5">
                            <Image className="col-12" src={Icon} rounded />
                            <div>
                                <h4 className="customMargin">Contactos</h4>
                                <div className="customMargin row pb-1">
                                    <div className=""><i className="fas fa-phone-square-alt fa-2x pr-2"></i></div>
                                    <div><a className="blackColor" href={'tel:' + phone}>{phone}</a></div>
                                </div>
                                <div className="customMargin row pb-1">
                                    <div className=""><i className="fas fa-envelope fa-2x pr-1"></i></div>
                                    <div><a className="blackColor" href={'mailto:' + email}>{email}</a></div>
                                </div>
                                <div className="customMargin row pb-1">
                                    <div className=""><i className="fab fa-facebook-square fa-2x pr-2"></i></div>
                                    <div><a className="blackColor" href={facebookLink} target="_blank">Facebook</a></div>
                                </div>
                                <div className="customMargin row pb-1">
                                    <div className=""><i className="fas fa-map-marker-alt fa-2x pr-2"></i></div>
                                    <div>
                                        <div>Fadas Verdadeiras, Lda</div>
                                        <div>3020-113 Coimbra</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <Form className="col-xs-12 col-sm-12 col-lg-8 pt-5" onSubmit={this.handleSubmit}>
                            <h4 className="pb-3">Formulário de Contacto</h4>
                            <Form.Group controlId="formNome">
                                <Form.Control required type="text" className="form-control" placeholder="Nome" onChange={this.handleNameChanges.bind(this)}/>
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Control required type="email" className="form-control" placeholder="Email" onChange={this.handleEmailChanges.bind(this)}/>
                            </Form.Group>
                            <Form.Group controlId="formContacto">
                                <Form.Control pattern="[0-9]{9}" required type="tel" className="form-control"  placeholder="Contacto" onChange={this.handlePhoneChanges.bind(this)}/>
                            </Form.Group>
                            <Form.Group controlId="formMensagem">
                                <Form.Control required as="textarea" rows={3} placeholder="Mensagem" onChange={this.handleMessagesChanges.bind(this)}/>
                            </Form.Group>
                            <Button className="butttonSend" variant="" type="submit">
                                Enviar
                            </Button> 
                            {/* spinner to handle server side action */}
                            <div key={this.state.showSpinner}><SpinnerC show={this.state.showSpinner}/></div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contactos;