// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    Redirect
  } from "react-router-dom";


import SobreNos from "../components/sobreNos/sobreNos";
import Produtos from "../components/produtos/produtos";
import Contactos from "../components/contactos/contactos";
import Candidaturas from "../components/candidaturas/candidaturas";
import Navigation from '../components/navigationBar/navigationBar';
import Termos from '../components/termosUtilizacao/termosUtilizacao';
import Privacidade from '../components/politicaPrivacidade/politicaPrivacidade';
import NotFound from '../components/notFound/notFound';
  

class Rotes extends React.Component{
    render() {
        return (
            <Router>
            {/* initial redirect if url does not cantaim param */}
                <Route exact path="/">
                    <Redirect to="/home" />
                    <Navigation selectedTab="home"/>
                </Route>

                {/* setting page according with the path */}
                {/* Switch component will render the first matched component */}
                <Switch>
                    <Route path="/home" render={(props) =><div> <Navigation selectedTab="home"/> <SobreNos/></div> }/> 
                    <Route path="/contactos" render={(props) =><div> <Navigation selectedTab="contactos"/> <Contactos/></div> }/> 
                    <Route path="/produtos" render={(props) =><div> <Navigation selectedTab="produtos"/> <Produtos/></div> }/> 
                    <Route path="/candidaturas" render={(props) => <div> <Navigation selectedTab="candidaturas"/> <Candidaturas/></div> }/> 
                    <Route path="/termos" render={(props) => <div> <Navigation selectedTab="termos"/> <Termos/></div> }/> 
                    <Route path="/privacidade" render={(props) => <div> <Navigation selectedTab="privacidade"/> <Privacidade/></div> }/> 
                    <Route render={(props) => <div> <Navigation selectedTab="notFound"/> <NotFound/></div> }/>
                </Switch>
                
            </Router>
        );
    }
}

export default Rotes;