// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react';
import customStyle from './navigationBar.css';
import logo from '../../images/logo_branco_sf.png'; 
import {Navbar,Nav,Form,FormControl,Button,Image} from 'react-bootstrap';

const tabs = [{name : 'Sobre Nós', api : 'home', classname : ''},
            {name : 'Produtos', api : 'produtos', classname : ''},
            {name : 'Candidaturas', api : 'candidaturas', classname : ''},
            {name : 'Contactos', api : 'contactos', classname : ''} ];


class Navigation extends React.Component {

  //construct method
  constructor(props) {
    super(props);

    //init var tabSelected
    this.state = {tabSelected: props.selectedTab, tabsObj : tabs};
  }

  componentDidMount(){
    this.setTabVisibility();
  }

  //set tabs visibility
  setTabVisibility(){
    //getting the values from the state 
    const tabSelected = this.state.tabSelected;

    //iterating over tabs to find out where to apply the active css class
    for(let i=0; i<tabs.length; i++){
        if(tabs[i].api === tabSelected){
          tabs[i].classname = 'activeTab';
          break;
        }
    }

    //setting with the correct values
    this.setState({tabsObj: tabs});
  }

  //handle link button
  handleClick(selectedTab){
    if(selectedTab){
      this.props.onNavigation(selectedTab);
    }
  }


  // render method
  render() {
      return (
        <div>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="customBg" fixed="top">
          <Navbar.Brand className="brand" href="/home"><Image className="brandImg" src={logo} rounded/></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                {/* iterating tabs */}
                {this.state.tabsObj.map((tab,key) => {
                    return <div key={key}><Nav.Link className={tab.classname} onClick={() => this.handleClick(tab.api)} href={'/' + tab.api}>{tab.name}</Nav.Link></div>
                })}
              </Nav> 
            </Navbar.Collapse>
          </Navbar>
        </div>
      );
    }
  }

  export default Navigation;