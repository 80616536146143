// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react';
import customStyle from './notFound.css';
import {Button} from 'react-bootstrap';


class NotFound extends React.Component {
    render() {
        return (
            <div className="notFound">
                <div className="containerCustom pt-5"><i className="fas fa-exclamation-triangle customSize"></i></div>
                <div className="containerCustom pt-5"><h2 className="pt-2">Oopss!</h2></div>
                <div className="containerCustom"><h4 className=" pt-2">A página especificada não existe</h4></div>
                <div className="containerCustom"> <a className="pt-5" href='/home'><Button variant="success">Página Inicial</Button>{''}</a></div>      
            </div>
        );
    };
}

export default NotFound;