// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, { useEffect, useState, render} from "react";
import customStyle from './alert.css';
import {Alert} from 'react-bootstrap';

function AlertC(props) {

    //init variables, using props
    const [showPopUp ,setShowPopUp] = useState( props.show );

    if (showPopUp === true) {
        return (
            <div className="col-12 alert" style={{top: '18px'}}>
            <Alert className="alertCustom" variant={props.variant} onClose={() => setShowPopUp(false)} dismissible>
                <Alert.Heading>{props.title}</Alert.Heading>
                <p>
                    {props.message}
                </p>
            </Alert>
            </div>

        );
    }
    return(<div></div>);
}

export default AlertC;