// Developed By : Rui Ramos
// Contact : ruiramos22@hotmail.com
// Date : 11/03/2021
import React, {Component} from 'react';
import customStyle from './footer.css';

const facebookLink = process.env.REACT_APP_FACEBOOK;
const email = 'mailto:' + process.env.REACT_APP_CONTACTO_EMAIL;
const phone = 'tel:' + process.env.REACT_APP_PHONE;

class Footer extends React.Component {

    render() {
        return (
            <footer className="footerStyle">
                <div className="height100">
                    <div className="height100">
                        {/* div with social networks */}
                        <div className="row col-12 m-auto pt-4">
                            {/* facebook */ }
                            <div className="m-auto customMarginRight">   
                                <a className="whiteColor text-center displayBlock" href={facebookLink} target="_blank">
                                    <i className="fab fa-facebook-square fa-2x"></i>
                                </a>
                            </div>
                            {/* email */ }
                            <div className="">   
                                <a className="whiteColor text-center displayBlock" href={email} target="_blank">
                                    <i className="fas fa-envelope fa-2x"></i>
                                </a>
                            </div>
                            {/* tel */ }

                            <div className="m-auto customMarginLeft">   
                                <a className="whiteColor text-center displayBlock" href={phone} target="_blank">
                                    <i className="fas fa-phone-square-alt fa-2x"></i>
                                </a>
                            </div>
                        </div>
                    
                         {/* div all rights reserved */}
                         <div className="pt-2 col-xs-12 col-sm-6 col-lg-3 m-auto">
                            <div className="text-center">Todos os Direitos Reservados, FV Solutions</div> 
                            <div className="text-center">{(new Date().getFullYear())}</div>
                         </div>

                        {/* div termos de utilização, politica de privacidade */}
                        <div className="pt-2">
                            <div className="row">
                                <div className="col"><a className="whiteColor text-end displayBlock fontSizeSmall" href="/termos">Termos e Condições</a></div><div>|</div>
                                <div className="col"><a className="whiteColor text-start displayBlock fontSizeSmall" href="/privacidade">Política de Privacidade</a></div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
